<template>
  <div class="inputs">
    <img class="smallimg" alt="Vue logo" src="../assets/outlineuser.png" />
    <h1>Login</h1>
    <FormulateForm v-model="formValues" @submit="submitHandler">
      <FormulateInput
        class="fform"
        name="email"
        label="Email"
        validation="required|email"
      />
      <FormulateInput
        class="fform"
        name="password"
        label="Password"
        type="password"
        validation="required"
      />
      <FormulateInput type="submit" label="Login" />
    </FormulateForm>

    <!-- <button @click="handlebutton">Login</button> -->
  </div>
</template>
<script>
// import { defineComponent } from '@vue/composition-api'
import axios from "axios";
export default {
  data() {
    return {
      formValues: {},
    };
  },
  computed: {
    localauth() {
      return this.$store.state.authenticated;
    },
    lochandle() {
      return this.$store.state.handle;
    },
  },
  mounted() {
    if (this.localauth) {
      this.$router.push("about");
    }
  },
  methods: {
    async submitHandler() {
      var data = new FormData();
      data.append("email", this.formValues.email);
      data.append("password", this.formValues.password);

      var config = {
        method: "post",
        url: "https://kontexbi.com/jslogin.php",
        withCredentials: false,
        headers: {},
        data: data,
      };

      await axios(config)
        .then((response) => {
          if (response.data.loggedin) {
            if (response.data.role == "Admin") {
              this.$store.commit("login", response.data);
              this.$router.push("about");
            } else {
              window.location.href = "https://kontexbi.com/ibot/ibot.php";
            }
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    login(data) {
      this.$store.commit("login");
      this.$router.push("about");
      alert(data);
    },
  },
};
</script>
