import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    authenticated: false,
    firstname: "",
    lastname: "",
    handle: "",
    email: "",
    role: "",
  },
  mutations: {
    logout(state) {
      state.authenticated = false;
    },
    login(state, data) {
      state.authenticated = true;
      state.firstname = data.firstname;
      state.role = data.role;
    },
  },
  actions: {},
  modules: {},
});
